/* Body and Global Settings */
body {
  font-family: Arial, sans-serif;
  background: linear-gradient(65deg, rgba(248, 248, 248, 1) 76%, rgba(211, 211, 211, 1) 99%);
  transition: background-color 2s ease, opacity 0.3s ease;
  margin: 0;
  /* Remove margins to prevent overflow */
  padding: 0 10px;
  /* Add padding to prevent text touching edges */
  overflow-x: hidden;
  color: #333;
  /* Dark text for readability on light background */
  box-sizing: border-box;
  /* Ensure padding and borders are included in total width and height */
}

* {
  font-family: "Anek Devanagari", sans-serif;
  box-sizing: inherit;
}

.intro {
  color: #333;
  /* Dark color for headings */
  text-align: center;
  padding: 20px;
}

/* Text and Headings */
h2,
h3 {
  color: #333;
  /* Dark color for headings */
  text-align: left;
}

h3 {
  font-size: 26px;
  font-weight: lighter;
  margin-top: 0px;
}

h2 {
  font-size: 36px;
  margin-bottom: 5px;
  margin-top: 30px;
}

/* Navigation and Links */
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin-left: 15px;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  font-weight: lighter;
  padding: 5px 15px;
  font-size: 20px;
  margin-bottom: 0px;
}

nav ul li a:hover {
  color: #007BFF;
  /* Primary color for hover */
  text-decoration: underline;
}

/* Introduction and General Text Elements */
.introduction-container {
  opacity: 0;
  animation: fade-in 0s ease forwards, drop-in 1s ease forwards;
  text-align: center;
  margin-bottom: 20px;
}

.typing-container {
  opacity: 0;
  animation: typing-fade-in 0s ease forwards, drop-in 1s ease forwards;
  animation-delay: 1s;
}

.typing {
  display: inline-block;
  color: #333;
  font-size: 48px;
  font-weight: lighter;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1s steps(100) 1s 1 forwards;
  vertical-align: middle;
  margin: 0;
  padding: 0 20px;
  /* Add padding to prevent text touching edges */
}

.typing span {
  background: linear-gradient(to right,
      rgb(84, 3, 142) 10%,
      rgb(138, 43, 226) 60%,
      rgb(172, 58, 200) 100%);
  font-size: 64px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
}

p {
  font-size: 18px;
  color: #333;
  text-align: left;
  margin: 20px 0;
  padding: 0 20px;
  /* Add padding to prevent text touching edges */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes typing-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes photo-slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes text-slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes typing {
  from {
    width: 0ch;
  }

  to {
    width: 25ch;
  }
}

.about-me-container {
  display: flex;
  align-items: center;
}

.image-container {
  flex: 0 0 25%;
  margin-right: 20px;
}

.image-container img {
  width: 80%;
  border-radius: 5%;
}

.description-container {
  flex: 1;
}

.fade-in span {
  background: linear-gradient(to right,
      #2d4f87 20%,
      /* UC San Diego Navy */
      #c78a1a 90%);
  /* UC San Diego Gold */
  font-size: 20px;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.social-icons a {
  color: #007BFF;
  margin-right: 10px;
  font-size: 30px;
  transition: color 0.3s ease-in-out;
}

.social-icons .github-icon {
  color: #333;
  /* GitHub's brand color */
}

.social-icons .linkedin-icon {
  color: #0077B5;
  /* LinkedIn's brand color */
}

.social-icons a:hover {
  color: #0056b3;
}

.social-icons .icon {
  vertical-align: middle;
}

/* Experience Section */
.experience-section {
  padding: 40px 20px;
  background-color: #F8F8F8;
}

.heading {
  font-size: 28px;
  color: #002255;
  /* UC San Diego Navy */
  text-align: left;
  margin-bottom: 30px;
}

.experiences-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.experience-entry {
  background: #fff;
  padding: 20px;
  border-left: 5px solid #c78a1a;
  /* UC San Diego Gold */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.experience-entry.visible {
  opacity: 1;
  transform: translateY(0);
}

.experience-title {
  font-size: 22px;
  color: #002255;
  /* UC San Diego Navy */
  font-weight: bold;
}

.experience-role {
  font-size: 18px;
  color: #555;
}

.experience-dates {
  font-style: italic;
  color: #888;
}

.experience-skills {
  font-size: 16px;
  color: #444;
  margin-top: 10px;
}

.experience-details {
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.experience-details li {
  font-size: 16px;
  color: #333;
}

/* Publications Section */
.publications-section {
  padding: 20px;
  color: #333;
  background-color: #f8f8f8;
  /* Light background for contrast */
  border-radius: 8px;
  margin-top: 20px;
}

.publications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.publications-list li {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.publication-title {
  font-size: 20px;
  color: #0056b3;
  /* UC San Diego blue color */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.publication-title:hover {
  color: #003366;
  /* Darker shade on hover */
}

.dropdown-icon {
  font-size: 16px;
  color: #0056b3;
}

.publication-details {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

.publication-details p {
  margin: 5px 0;
}

.publication-details a {
  color: #0056b3;
  text-decoration: none;
}

.publication-details a:hover {
  text-decoration: underline;
}

.publication-details p {
  font-size: 16px;
  color: #333;
  text-align: left;
  margin-left: 20px;
}

/* Projects Section */
.projects-container {
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 8px;
  max-width: 800px;
  margin: 50px auto;
}

.project-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.project-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-header h3 {
  font-size: 22px;
  color: #333;
}

.icons {
  display: flex;
  align-items: center;
}

.icon-proj {
  font-size: 24px;
  margin-left: 10px;
  color: #000;
}

.project-details {
  margin-top: 20px;
}

.project-details p {
  font-size: 16px;
  color: #555;
}

.tech-stack {
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

/* Contact Section */
.contact-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  width: 80%;
  max-width: 600px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
}

.contact-icon {
  font-size: 24px;
  color: #0056b3;
}

.contact-details a {
  color: #0056b3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-details a:hover {
  color: #003d7a;
}

.resume-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.resume-button:hover {
  background-color: #003d7a;
  transform: translateY(-2px);
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  body {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 10px;
  }

  .introduction-container,
  .about,
  .typing,
  p {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .experience-section,
  .projects-container,
  .contact-section {
    width: 100%;
    padding: 20px;
    margin: 20px auto;
  }

  .experience-title,
  .project-header h3,
  .publication-title {
    font-size: 18px;
  }

  .experience-role,
  .project-details p,
  .publication-details p {
    font-size: 14px;
  }

  .heading {
    font-size: 24px;
  }

  .social-icons a {
    font-size: 24px;
  }

  .icon-proj {
    font-size: 20px;
  }

  .resume-button {
    padding: 10px;
    font-size: 14px;
  }

  .project-cards {
    grid-template-columns: 1fr;
  }

  .typing {
    font-size: 24px;
  }

  .typing span {
    font-size: 32px;
  }
}